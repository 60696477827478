import React from 'react'
import Layout from '../components/layout'
import Prints from '../components/Prints'
import { graphql } from 'gatsby'
import ContentBlock from '../components/contentBlock'
import SEO from '../components/seo'

const OtherArtworkSeries = ({ data }) => (
  <Layout>
    <SEO title={data.wordpressPage.title} />
    <ContentBlock>
      <h1>{data.wordpressPage.title}</h1>
      <div
        dangerouslySetInnerHTML={{
          __html: data.wordpressPage.content,
        }}
      />
    </ContentBlock>
    <Prints artwork={data.allWordpressWpArtwork.edges} imgType="Print" series="Other" />
  </Layout>
)

export default OtherArtworkSeries

export const query = graphql`
  query OtherArtQuery {
    allWordpressWpArtwork(sort: { fields: acf___order, order: ASC }) {
      edges {
        node {
          acf {
            description
            prints_description
            etsy_link
            image_type
            series
          }
          id
          title
          featured_media {
            localFile {
              childImageSharp {
                fluid(maxHeight: 500) {
                  ...GatsbyImageSharpFluid_tracedSVG
                }
              }
            }
          }
        }
      }
    }
    wordpressPage(slug: { eq: "other-artwork" }) {
      title
      content
    }
  }
`
